/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Timestamp
 */
export interface Timestamp {
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof Timestamp
     */
    nanos?: number;
}

export function TimestampFromJSON(json: any): Timestamp {
    return TimestampFromJSONTyped(json, false);
}

export function TimestampFromJSONTyped(json: any, ignoreDiscriminator: boolean): Timestamp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'seconds': !exists(json, 'seconds') ? undefined : json['seconds'],
        'nanos': !exists(json, 'nanos') ? undefined : json['nanos'],
    };
}

export function TimestampToJSON(value?: Timestamp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'seconds': value.seconds,
        'nanos': value.nanos,
    };
}


