import React from "react";
import Dashboard from "./dashboard";

export default ({ children, pageContext, location }) => {
    if (pageContext.layout === "none") {
        return (<div>{children}</div>);
    }
    if (pageContext.layout === "dashboard") {
        return (<Dashboard location={location}>{children}</Dashboard>);
    }
    return (<div>{children}</div>);
};
