/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Company,
    CompanyFromJSON,
    CompanyFromJSONTyped,
    CompanyToJSON,
    Meter,
    MeterFromJSON,
    MeterFromJSONTyped,
    MeterToJSON,
    Timestamp,
    TimestampFromJSON,
    TimestampFromJSONTyped,
    TimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface Device
 */
export interface Device {
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceId?: string | null;
    /**
     * 
     * @type {Meter}
     * @memberof Device
     */
    meter?: Meter | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    companyId?: string | null;
    /**
     * 
     * @type {Company}
     * @memberof Device
     */
    company?: Company | null;
    /**
     * 
     * @type {string}
     * @memberof Device
     */
    deviceUuid?: string | null;
    /**
     * 
     * @type {Timestamp}
     * @memberof Device
     */
    created?: Timestamp | null;
    /**
     * 
     * @type {Timestamp}
     * @memberof Device
     */
    lastUpdated?: Timestamp | null;
}

export function DeviceFromJSON(json: any): Device {
    return DeviceFromJSONTyped(json, false);
}

export function DeviceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Device {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'meter': !exists(json, 'meter') ? undefined : MeterFromJSON(json['meter']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'company': !exists(json, 'company') ? undefined : CompanyFromJSON(json['company']),
        'deviceUuid': !exists(json, 'deviceUuid') ? undefined : json['deviceUuid'],
        'created': !exists(json, 'created') ? undefined : TimestampFromJSON(json['created']),
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : TimestampFromJSON(json['lastUpdated']),
    };
}

export function DeviceToJSON(value?: Device | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'deviceId': value.deviceId,
        'meter': MeterToJSON(value.meter),
        'companyId': value.companyId,
        'company': CompanyToJSON(value.company),
        'deviceUuid': value.deviceUuid,
        'created': TimestampToJSON(value.created),
        'lastUpdated': TimestampToJSON(value.lastUpdated),
    };
}


