/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LoginRequest,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponse,
    LoginResponseFromJSON,
    LoginResponseToJSON,
} from '../models';

export interface ApiAuthPostRequest {
    loginRequest?: LoginRequest;
}

/**
 * no description
 */
export class AuthApi extends runtime.BaseAPI {

    /**
     */
    async apiAuthPostRaw(requestParameters: ApiAuthPostRequest): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: runtime.HTTPQuery = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // Bearer authentication
        }

        const response = await this.request({
            path: `/api/Auth`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters.loginRequest),
        });

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async apiAuthPost(requestParameters: ApiAuthPostRequest): Promise<LoginResponse> {
        const response = await this.apiAuthPostRaw(requestParameters);
        return await response.value();
    }

}
