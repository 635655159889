// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-dashboard-tsx": () => import("./../src/pages/dashboard.tsx" /* webpackChunkName: "component---src-pages-dashboard-tsx" */),
  "component---src-pages-dashboard-companies-tsx": () => import("./../src/pages/dashboard/companies.tsx" /* webpackChunkName: "component---src-pages-dashboard-companies-tsx" */),
  "component---src-pages-dashboard-company-tsx": () => import("./../src/pages/dashboard/company.tsx" /* webpackChunkName: "component---src-pages-dashboard-company-tsx" */),
  "component---src-pages-dashboard-overview-tsx": () => import("./../src/pages/dashboard/overview.tsx" /* webpackChunkName: "component---src-pages-dashboard-overview-tsx" */),
  "component---src-pages-dashboard-service-tsx": () => import("./../src/pages/dashboard/service.tsx" /* webpackChunkName: "component---src-pages-dashboard-service-tsx" */),
  "component---src-pages-dashboard-services-tsx": () => import("./../src/pages/dashboard/services.tsx" /* webpackChunkName: "component---src-pages-dashboard-services-tsx" */),
  "component---src-pages-dashboard-users-tsx": () => import("./../src/pages/dashboard/users.tsx" /* webpackChunkName: "component---src-pages-dashboard-users-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */)
}

