import React, { useState, useEffect } from "react";
import { Layout, Menu, Breadcrumb, Icon, Button } from 'antd';
import { Link, navigate } from "gatsby";
import { validateToken, setHanData } from "../state/actions";
import { useGlobalState } from "../state";
import { SizeMe, withSize } from 'react-sizeme'
import * as signalR from "@microsoft/signalr";

const { SubMenu } = Menu;
const { Header, Content, Footer, Sider } = Layout;

// @ts-ignore
import Conviviallogo from "../../static/conviviallogo.inline.svg";

// @ts-ignore
import Power from "../../static/power.inline.svg";

function Dashboard({ children, location, size }) {

    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [breadcrumbs, setBreadcrumbs] = useState<string[]>([]);
    const [token] = useGlobalState('token');

    function handleClick(e) {
        setSelectedKeys([e.key]);

        //if (e.key === "1") { navigate('/dashboard/companies'); }
        //if (e.key === "2") { navigate('/dashboard/users'); }
        //if (e.key === "3") { navigate('/dashboard/services'); }
    }

    function capitalizeFirstLetter(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    useEffect(() => {
        const isValid = validateToken(token, location.pathname, navigate);
    }, [token]);

    useEffect(() => {
        const connection = new signalR.HubConnectionBuilder().withUrl(process.env.GATSBY_POWERAPI_URL + "/Realtime",  { accessTokenFactory: () => token.jwtToken }).build();

        /*connection.on("ProcessData", data => {
            const processdata = { numvalue: data.numvalue, tag: data.variable, time: data.time }
            setProcessdata(processdata);
        });


        connection.on("AlarmData", data => {
            setAlarmdata(data);
            //console.log(data);
        });*/

        connection.on("HanData", data => {
            setHanData(data);
            console.log(data);
        });

        connection.start().catch(err => {
            //console.log(err));
        });
    }, []);

    useEffect(() => {
        //console.log(location);
        const paths = location.pathname.split("/");
        setBreadcrumbs(paths);
    }, [location]);

    const breadcrumbList = breadcrumbs.map((path) =>
        <Breadcrumb.Item key={path}>{path}</Breadcrumb.Item>
    );

    const logout = () => {
        console.log("logout")
    }

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider  collapsed={true} style={{backgroundColor: "#FFF"}}>
                <div className="logo" />
                <div style={{margin: "0 auto", width: 33, marginTop: 24, marginBottom: 24}}>
                    <Power/>
                </div>
                <Menu theme="light" defaultSelectedKeys={['1']} style={{borderRight: 0}} mode="inline">
                    <Menu.Item key="1" >
                        <Icon type="home" style={{fontSize: 24, marginLeft: -3}}/>
                        <span>Hjem</span>
                    </Menu.Item>
                </Menu>
                <Button type="link" icon="logout" style={{position: "absolute", bottom: 0, margin: "0 auto", fontSize: 24, marginBottom: 24, marginLeft: 24}} onClick={logout}></Button>
            </Sider>
            <Layout>
                <Content style={{ backgroundColor: "#F9F9F9", marginLeft: 2, marginRight: 2 }}>
                    {children}
                </Content>
            </Layout>
            <Sider width={350} style={{backgroundColor: "#FFF", marginLeft: 2}}>

            </Sider>
        </Layout>);
}

export default withSize()(Dashboard);
