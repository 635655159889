import produce from "immer";
import { Action, ActionTypes, initialState } from ".";
import { LoginResponse } from "../service";
import { navigate } from "@reach/router";
import { ServiceSettingValue } from "../service/src";

export const loginReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.LOGIN_SUCCESS:
        const data: LoginResponse = action.data as LoginResponse;
        draft.token = action.data.token;
        break;
    }
  });


export const deviceReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.DEVICES_GET_SUCCESS:
        draft.devices = action.data.devices;
        break;
    }
  });

export const hanDataReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionTypes.HANDATA_SET_SUCCESS:
        draft.handata.push(action.data);
        break;
    }
  });

/*
export const companyReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.COMPANIES_GET_SUCCESS:
        draft.companies = action.data.companies;
        break;
      case ActionTypes.COMPANIES_POST_SUCCESS:
        // UPDATE -> draft[draft.companies.findIndex(company => company.companyId === action.data.company.companyId)] = action.data.company.companyId;
        draft.companies.push(action.data.company);
        break;

      case ActionTypes.COMPANIES_POSTSERVICE_SUCCESS:
      case ActionTypes.COMPANIES_GETBYID_SUCCESS:
        const i = draft.companies.findIndex(company => company.companyId === action.data.company.companyId);
        if (i !== -1) {
          draft.companies[i] = action.data.company;
        } else {
          draft.companies.push(action.data.company);
        }
        break;
    }
  });

export const serviceSettingValueReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.COMPANIES_PATCHSERVICESETTINGVALUES_SUCCESS:
      case ActionTypes.COMPANIES_GETSERVICESETTINGVALUES_SUCCESS:
        //const i = draft.companies.findIndex(company => company.companyId === action.data.company.companyId);
        draft.serviceSettingValues = action.data.serviceSettingValues;
        break;

     
    }
  });

export const userReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.USERS_GET_SUCCESS:
        draft.users = action.data.users;
        break;
    }
  });

export const serviceReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.SERVICES_GET_SUCCESS:
        draft.services = action.data.services;
        break;

      case ActionTypes.SERVICES_POST_SUCCESS:
        draft.services.push(action.data.service);
        break;

      case ActionTypes.SERVICES_PATCH_SUCCESS:
      case ActionTypes.SERVICES_GETBYID_SUCCESS:
        console.log("yep")
        const i = draft.services.findIndex(service => service.serviceId === action.data.service.serviceId);
        if (i !== -1) {
          draft.services[i] = action.data.service;
        } else {
          draft.services.push(action.data.service);
        }
        break;
    }
  });


export const serviceSettingReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.SERVICESETTINGS_POST_SUCCESS:
        const i = draft.services.findIndex(service => service.serviceId === action.data.serviceSetting.serviceId);
        if (i !== -1) {
          draft.services[i].serviceSettings.push(action.data.serviceSetting);
        } else {
          // Invalid service - drop!
        }
        break;
    }
  });
  */


export const tokenReducer = (state = initialState, action: Action) =>
  produce(state, (draft) => {

    switch (action.type) {
      case ActionTypes.TOKEN_VALID:
        draft.isLoggedIn = true;
        break;
      case ActionTypes.TOKEN_INVALID:
        draft.isLoggedIn = false;
        draft.token = {};
        break;
      case ActionTypes.ACCOUNTINFO_SET:
        draft.accountInformation = { name: action.data.name, company: action.data.company, initials: action.data.initials };
        break;
      case ActionTypes.USER_LOGOUT:
        draft.token = {};
        //navigate('/');
        break;
    }
  });
