/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CompanyService,
    CompanyServiceFromJSON,
    CompanyServiceFromJSONTyped,
    CompanyServiceToJSON,
    Timestamp,
    TimestampFromJSON,
    TimestampFromJSONTyped,
    TimestampToJSON,
} from './';

/**
 * 
 * @export
 * @interface Company
 */
export interface Company {
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    orgId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Company
     */
    description?: string | null;
    /**
     * 
     * @type {Array<CompanyService>}
     * @memberof Company
     */
    readonly services?: Array<CompanyService> | null;
    /**
     * 
     * @type {Timestamp}
     * @memberof Company
     */
    created?: Timestamp | null;
    /**
     * 
     * @type {Timestamp}
     * @memberof Company
     */
    lastUpdated?: Timestamp | null;
}

export function CompanyFromJSON(json: any): Company {
    return CompanyFromJSONTyped(json, false);
}

export function CompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Company {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'orgId': !exists(json, 'orgId') ? undefined : json['orgId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'services': !exists(json, 'services') ? undefined : (json['services'] === null ? null : (json['services'] as Array<any>).map(CompanyServiceFromJSON)),
        'created': !exists(json, 'created') ? undefined : TimestampFromJSON(json['created']),
        'lastUpdated': !exists(json, 'lastUpdated') ? undefined : TimestampFromJSON(json['lastUpdated']),
    };
}

export function CompanyToJSON(value?: Company | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'name': value.name,
        'orgId': value.orgId,
        'description': value.description,
        'created': TimestampToJSON(value.created),
        'lastUpdated': TimestampToJSON(value.lastUpdated),
    };
}


