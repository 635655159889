import _ from 'lodash';
import {
  createStore,
  Dispatch,
  Enhancer,
  Store,
} from 'react-hooks-global-state';
import { isNullOrUndefined } from 'util';
import { LoginResponse, Token } from '../service/api';
import produce from "immer";

import { loginReducer, tokenReducer, deviceReducer, hanDataReducer } from './reducers';
import { CompaniesGetResponse, Company, User, UsersGetResponse, Service, ServicesGetResponse, CompanyPostResponse, CompanyGetResponse, ServiceGetResponse, ServicePostResponse, ServiceSettingPostResponse, CompanyPostServiceResponse, CompanyGetServiceSettingValuesResponse, CompanyPatchServiceSettingValuesResponse, ServiceSettingValue, UserPostRequest } from '../service/src';
import { ServicePatchResponse } from '../service/src/models/ServicePatchResponse';
import { UserPostResponse, GetDeviceResponse, Device, HanData } from '../service';

const windowGlobal = typeof window !== 'undefined' && window;

interface ILoginState {
  isLoggingIn: boolean;
}

export interface IAcocuntInformation {
  name: string;
  company: string;
  initials: string;
}

export interface State {
  token: Token;
  login: ILoginState;
  devices: Device[];
  handata: HanData[];
}

export enum ActionTypes {
    USER_LOGOUT = 'USER_LOGOUT',
    LOGIN_STARTED = 'LOGIN_STARTED',
    LOGIN_SUCCESS = 'LOGIN_SUCCESS',
    LOGIN_FAILED = 'LOGIN_FAILED',
    TOKEN_VALID = 'TOKEN_VALID',
    TOKEN_INVALID = 'TOKEN_INVALID',
    DEVICES_GET_STARTED = "DEVICES_GET_STARTED",
    DEVICES_GET_SUCCESS = "DEVICES_GET_SUCCESS",
    DEVICES_GET_FAILED = "DEVICES_GET_FAILED",
    HANDATA_SET = "HANDATA_SET",
    HANDATA_SET_SUCCESS = "HANDATA_SET_SUCCESS",
    HANDATA_SET_FAILED = "HANDATA_SET_FAILED"
}

export type Action =
  | { type: ActionTypes.HANDATA_SET_SUCCESS, data: HanData }
  | { type: ActionTypes.DEVICES_GET_STARTED }
  | { type: ActionTypes.DEVICES_GET_SUCCESS, data: GetDeviceResponse }
  | { type: ActionTypes.DEVICES_GET_FAILED }
  | { type: ActionTypes.LOGIN_STARTED }
  | { type: ActionTypes.USER_LOGOUT }
  | { type: ActionTypes.TOKEN_VALID }
  | { type: ActionTypes.TOKEN_INVALID }
  | { type: ActionTypes.LOGIN_SUCCESS, data: LoginResponse }
  | { type: ActionTypes.LOGIN_FAILED, message: string };

type Middleware = (store: Store<State, Action>) => (next: Dispatch<Action>) => Dispatch<Action>;
type ApplyMiddleware = (...args: Middleware[]) => Enhancer<State, Action>;

const applyMiddleware: ApplyMiddleware = (...args) => (creator) => {
  const [first, ...rest] = args;
  if (!first) { return creator; }
  creator = applyMiddleware(...rest)(creator);
  return (reducer, initialState) => {
    const store = creator(reducer, initialState);
    const dispatch = first(store)(store.dispatch);
    return { ...store, dispatch };
  };
};

const defaultState: State = {
  token: {},
  login: { isLoggingIn: false },
  devices: [],
  handata: [],
};

const LOCAL_STORAGE_KEY = 'storage';
const parseState = (str: string | null): State | null => {
  try {
    const state = JSON.parse(str || '');

    // Null checks
    if (state.token === null || state.token === undefined) { state.token = []; }
    if (state.devices === null || state.devices === undefined) { state.devices = []; }
    if (state.handata === null || state.handata === undefined) { state.handata = []; }

    return state as State;
  } catch (e) {
    return null;
  }
};

function getDataFromLocalStorage() {
  if (windowGlobal) {
    return windowGlobal.localStorage.getItem(LOCAL_STORAGE_KEY);
  }
}

const stateFromStorage = parseState(getDataFromLocalStorage());
export const initialState: State = stateFromStorage || defaultState;

const saveStateToStorage = ({ getState }: { getState: () => State },
) => (next: Dispatch<Action>) => (action: Action) => {
  const returnValue = next(action);
  console.log(action);

  if (windowGlobal) {
    let s = getState();
    var ss = Object.assign({}, s);
    ss.handata = [];
    windowGlobal.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(ss));
  }
  return returnValue;
};

const reduceReducers = (...reducers) => (prevState, value, ...args) =>
  reducers.reduce(
    (newState, reducer) => reducer(newState, value, ...args),
    prevState,
  );

export const { GlobalStateProvider, dispatch, useGlobalState } = createStore(
  reduceReducers(loginReducer, tokenReducer, deviceReducer, hanDataReducer),
  initialState,
  applyMiddleware(saveStateToStorage),
);
