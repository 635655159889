/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Meter
 */
export interface Meter {
    /**
     * 
     * @type {string}
     * @memberof Meter
     */
    meterId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Meter
     */
    meterMeteringId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Meter
     */
    deviceId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Meter
     */
    locationId?: string | null;
    /**
     * 
     * @type {Location}
     * @memberof Meter
     */
    location?: Location | null;
    /**
     * 
     * @type {number}
     * @memberof Meter
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Meter
     */
    longitude?: number;
    /**
     * 
     * @type {string}
     * @memberof Meter
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Meter
     */
    constant?: number;
}

export function MeterFromJSON(json: any): Meter {
    return MeterFromJSONTyped(json, false);
}

export function MeterFromJSONTyped(json: any, ignoreDiscriminator: boolean): Meter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meterId': !exists(json, 'meterId') ? undefined : json['meterId'],
        'meterMeteringId': !exists(json, 'meterMeteringId') ? undefined : json['meterMeteringId'],
        'deviceId': !exists(json, 'deviceId') ? undefined : json['deviceId'],
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'constant': !exists(json, 'constant') ? undefined : json['constant'],
    };
}

export function MeterToJSON(value?: Meter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meterId': value.meterId,
        'meterMeteringId': value.meterMeteringId,
        'deviceId': value.deviceId,
        'locationId': value.locationId,
        'location': LocationToJSON(value.location),
        'latitude': value.latitude,
        'longitude': value.longitude,
        'description': value.description,
        'constant': value.constant,
    };
}


