/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CompanyService
 */
export interface CompanyService {
    /**
     * 
     * @type {string}
     * @memberof CompanyService
     */
    companyId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyService
     */
    serviceId?: string | null;
}

export function CompanyServiceFromJSON(json: any): CompanyService {
    return CompanyServiceFromJSONTyped(json, false);
}

export function CompanyServiceFromJSONTyped(json: any, ignoreDiscriminator: boolean): CompanyService {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'serviceId': !exists(json, 'serviceId') ? undefined : json['serviceId'],
    };
}

export function CompanyServiceToJSON(value?: CompanyService | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companyId': value.companyId,
        'serviceId': value.serviceId,
    };
}


