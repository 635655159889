/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Group,
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
    Meter,
    MeterFromJSON,
    MeterFromJSONTyped,
    MeterToJSON,
} from './';

/**
 * 
 * @export
 * @interface Location
 */
export interface Location {
    /**
     * 
     * @type {Array<Meter>}
     * @memberof Location
     */
    meters?: Array<Meter> | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    locationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    groupId?: string | null;
    /**
     * 
     * @type {Group}
     * @memberof Location
     */
    group?: Group | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Location
     */
    description?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    latitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Location
     */
    longitude?: number;
}

export function LocationFromJSON(json: any): Location {
    return LocationFromJSONTyped(json, false);
}

export function LocationFromJSONTyped(json: any, ignoreDiscriminator: boolean): Location {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'meters': !exists(json, 'meters') ? undefined : (json['meters'] === null ? null : (json['meters'] as Array<any>).map(MeterFromJSON)),
        'locationId': !exists(json, 'locationId') ? undefined : json['locationId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'group': !exists(json, 'group') ? undefined : GroupFromJSON(json['group']),
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
    };
}

export function LocationToJSON(value?: Location | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'meters': value.meters === undefined ? undefined : (value.meters === null ? null : (value.meters as Array<any>).map(MeterToJSON)),
        'locationId': value.locationId,
        'groupId': value.groupId,
        'group': GroupToJSON(value.group),
        'name': value.name,
        'description': value.description,
        'latitude': value.latitude,
        'longitude': value.longitude,
    };
}


