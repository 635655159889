import uuidv4 from 'uuid/v4';
import { ActionTypes, dispatch, useGlobalState, State } from '.';
import jwt_decode from 'jwt-decode';
import { parse } from 'querystring';
import { Token, ConfigurationParameters, LoginRequest, AuthApi, Configuration, ApiAuthPostRequest, GetDeviceResponse, DeviceApi, HanData } from '../service';



function getToken(): Token {
    try {
        const _storage = window.localStorage.getItem('storage');
        const storage: State = (JSON.parse(_storage));
        return storage.token;
    } catch (err) {
        //dispatch({ type: ActionTypes.LOOKUP_BY_METER_NUMBER_SUCCESS, request: lookupRequest, response: result, id: uuid });
        return {};
    }
}

function getConfig(includeToken: boolean = true): ConfigurationParameters {
    if (includeToken) {
        return {
            apiKey: "Bearer " + (getToken() as Token).jwtToken,
            basePath: process.env.GATSBY_POWERAPI_URL,
        };
    } else {
        return {
            basePath: process.env.GATSBY_POWERAPI_URL,
        };
    }
}

export async function login(_loginRequest: ApiAuthPostRequest): Promise<void> {
    dispatch({ type: ActionTypes.LOGIN_STARTED });
    try {
        console.log(_loginRequest);
        const authapi: AuthApi = new AuthApi(new Configuration(getConfig(false)));
        const result = await authapi.apiAuthPost(_loginRequest);
        dispatch({ type: ActionTypes.LOGIN_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.LOGIN_FAILED, message: error });
        throw error;
    }
}


//
//
// Devices
//
//
export async function getDevices(): Promise<GetDeviceResponse> {
    dispatch({ type: ActionTypes.DEVICES_GET_STARTED });
    try {
        const deviceapi: DeviceApi = new DeviceApi(new Configuration(getConfig(true)));
        const result = await deviceapi.apiDeviceGet();
        dispatch({ type: ActionTypes.DEVICES_GET_SUCCESS, data: result });
        return result;
    } catch (error) {
        dispatch({ type: ActionTypes.DEVICES_GET_FAILED, message: error });
        throw error;
    }
}

//
//
// HanData
//
//
export async function setHanData(hanData: HanData): Promise<void> {
    try {
        dispatch({ type: ActionTypes.HANDATA_SET_SUCCESS, data: hanData });
    } catch (error) {
        dispatch({ type: ActionTypes.HANDATA_SET_FAILED, message: error });
        throw error;
    }
}

/*
export async function getCompanies(): Promise<void> {
    dispatch({ type: ActionTypes.COMPANIES_GET_STARTED });
    try {
        const companyapi: CompanyApi = new CompanyApi(new Configuration(getConfig(true)));
        const result = await companyapi.get();
        dispatch({ type: ActionTypes.COMPANIES_GET_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.COMPANIES_GET_FAILED, message: error });
        throw error;
    }
}

export async function newCompany(request: PostRequest): Promise<void> {
    dispatch({ type: ActionTypes.COMPANIES_POST_STARTED });
    try {
        const companyapi: CompanyApi = new CompanyApi(new Configuration(getConfig(true)));
        const result = await companyapi.post(request);
        dispatch({ type: ActionTypes.COMPANIES_POST_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.COMPANIES_POST_FAILED, message: error });
        throw error;
    }
}

export async function newCompanyService(_companyId: string, _request: CompanyPostServiceRequest): Promise<void> {
    dispatch({ type: ActionTypes.COMPANIES_POSTSERVICE_STARTED });
    try {
        const companyapi: CompanyApi = new CompanyApi(new Configuration(getConfig(true)));
        const result = await companyapi.postService({companyId: _companyId, request: _request});
        dispatch({ type: ActionTypes.COMPANIES_POSTSERVICE_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.COMPANIES_POSTSERVICE_FAILED, message: error });
        throw error;
    }
}

export async function getCompanyServiceSettingValues(_companyId: string, _serviceId: string): Promise<void> {
    dispatch({ type: ActionTypes.COMPANIES_GETSERVICESETTINGVALUES_STARTED });
    try {
        const companyapi: CompanyApi = new CompanyApi(new Configuration(getConfig(true)));
        const result = await companyapi.getCompanyServiceSettingValues({companyId: _companyId, serviceId: _serviceId});
        dispatch({ type: ActionTypes.COMPANIES_GETSERVICESETTINGVALUES_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.COMPANIES_GETSERVICESETTINGVALUES_FAILED, message: error });
        throw error;
    }
}

export async function setCompanyServiceSettingValues(_companyId: string, _serviceId: string, _request: CompanyPatchServiceSettingValuesRequest): Promise<void> {
    dispatch({ type: ActionTypes.COMPANIES_PATCHSERVICESETTINGVALUES_STARTED });
    try {
        const companyapi: CompanyApi = new CompanyApi(new Configuration(getConfig(true)));
        console.log(_request);
        const result = await companyapi.patchCompanyServiceSettingValues({companyId: _companyId, serviceId: _serviceId, request: _request});
        dispatch({ type: ActionTypes.COMPANIES_PATCHSERVICESETTINGVALUES_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.COMPANIES_PATCHSERVICESETTINGVALUES_FAILED, message: error });
        throw error;
    }
}

//
//
// User
//
//
export async function getUsers(): Promise<void> {
    dispatch({ type: ActionTypes.USERS_GET_STARTED });
    try {
        const userapi: UserApi = new UserApi(new Configuration(getConfig(true)));
        const result = await userapi.get();
        dispatch({ type: ActionTypes.USERS_GET_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.USERS_GET_FAILED, message: error });
        throw error;
    }
}

export async function newUser(_request: UserPostRequest): Promise<void> {
    dispatch({ type: ActionTypes.USER_POST_STARTED });
    try {
        const userapi: UserApi = new UserApi(new Configuration(getConfig(true)));
        const result = await userapi.post({request: _request});
        dispatch({ type: ActionTypes.USER_POST_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.USER_POST_FAILED, message: error });
        throw error;
    }
}

//
//
// Service
//
//
export async function getService(request: GetServiceByIdRequest): Promise<void> {
    dispatch({ type: ActionTypes.SERVICES_GETBYID_STARTED });
    try {
        const serviceapi: ServiceApi = new ServiceApi(new Configuration(getConfig(true)));
        const result = await serviceapi.getServiceById(request);
        dispatch({ type: ActionTypes.SERVICES_GETBYID_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.SERVICES_GETBYID_FAILED, message: error });
        throw error;
    }
}

export async function getServices(): Promise<void> {
    dispatch({ type: ActionTypes.SERVICES_GET_STARTED });
    try {
        const serviceapi: ServiceApi = new ServiceApi(new Configuration(getConfig(true)));
        const result = await serviceapi.get();
        dispatch({ type: ActionTypes.SERVICES_GET_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.SERVICES_GET_FAILED, message: error });
        throw error;
    }
}

export async function newService(request: ServicePostRequest): Promise<void> {
    dispatch({ type: ActionTypes.SERVICES_POST_STARTED });
    try {
        const serviceapi: ServiceApi = new ServiceApi(new Configuration(getConfig(true)));
        const result = await serviceapi.post(request);
        dispatch({ type: ActionTypes.SERVICES_POST_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.SERVICES_POST_FAILED, message: error });
        throw error;
    }
}

export async function updateService(request: ServicePatchRequest): Promise<void> {
    dispatch({ type: ActionTypes.SERVICES_PATCH_STARTED });
    try {
        console.log("Request: ", request);
        const serviceapi: ServiceApi = new ServiceApi(new Configuration(getConfig(true)));
        const result = await serviceapi.patch(request);
        dispatch({ type: ActionTypes.SERVICES_PATCH_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.SERVICES_PATCH_FAILED, message: error });
        throw error;
    }
}

//
//
// ServiceSetting
//
//
export async function newServiceSetting(_request: ServiceSettingPostRequest): Promise<void> {
    dispatch({ type: ActionTypes.SERVICESETTINGS_POST_STARTED });
    try {
        const servicesettingapi: ServiceSettingApi = new ServiceSettingApi(new Configuration(getConfig(true)));
        const result = await servicesettingapi.post({request: _request});
        dispatch({ type: ActionTypes.SERVICESETTINGS_POST_SUCCESS, data: result });
        return;
    } catch (error) {
        dispatch({ type: ActionTypes.SERVICESETTINGS_POST_FAILED, message: error });
        throw error;
    }
}
*/

function parseErrorResponse(error) {
    if (error.status === 401) {
        dispatch({ type: ActionTypes.USER_LOGOUT });
    }
}

export function validateToken(token: Token, pathname: string, navigate: any) {
    console.log("validate");
    try {
        const decoded = jwt_decode(token.jwtToken);
        const isTokenValid = new Date(decoded.exp * 1000) > new Date();
        console.log("Validate");
        if (isTokenValid) {
            getTokenClaims();
            if (pathname === '/') {
                navigate('/dashboard');
            }
            dispatch({ type: ActionTypes.TOKEN_VALID });
        } else {
            if (pathname !== '/') {
                navigate('/');
            }
            //dispatch({ type: ActionTypes.TOKEN_INVALID });
            // dispatch({ type: ActionTypes.LOOKUP_BY_METER_NUMBER_FAILED, request: lookupRequest, message: error, id: uuid });
        }
    } catch (error) {
        // Failed to decode token. It must be invalid then.
        //
        //dispatch({ type: ActionTypes.USER_LOGOUT });

        if (pathname !== '/') {
            navigate('/');
            //dispatch({ type: ActionTypes.TOKEN_INVALID });
        } else {
            
        }
    }
}


export function getTokenClaims() {
    try {
        const token = getToken();
        const decoded = jwt_decode(token.jwtToken);
        const isTokenValid = new Date(decoded.exp * 1000) > new Date();
        //console.log(decoded);
        if (isTokenValid) {
            const _name = decoded['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name'];
            const names = _name.split(' ');
            let _initials = names[0].substring(0, 1).toUpperCase();

            if (names.length > 1) {
                _initials += names[names.length - 1].substring(0, 1).toUpperCase();
            }
            //dispatch({ type: ActionTypes.ACCOUNTINFO_SET, data: { name: _name, company: "", initials: _initials } });
        } else {
            // dispatch({ type: ActionTypes.LOOKUP_BY_METER_NUMBER_FAILED, request: lookupRequest, message: error, id: uuid });
        }
    } catch (error) {
        // Failed to decode token. It must be invalid then
    }
}
