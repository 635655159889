/* tslint:disable */
/* eslint-disable */
/**
 * powerapi
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Location,
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './';

/**
 * 
 * @export
 * @interface Group
 */
export interface Group {
    /**
     * 
     * @type {Array<Location>}
     * @memberof Group
     */
    locations?: Array<Location> | null;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    groupId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Group
     */
    description?: string | null;
}

export function GroupFromJSON(json: any): Group {
    return GroupFromJSONTyped(json, false);
}

export function GroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): Group {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'locations': !exists(json, 'locations') ? undefined : (json['locations'] === null ? null : (json['locations'] as Array<any>).map(LocationFromJSON)),
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
    };
}

export function GroupToJSON(value?: Group | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'locations': value.locations === undefined ? undefined : (value.locations === null ? null : (value.locations as Array<any>).map(LocationToJSON)),
        'groupId': value.groupId,
        'name': value.name,
        'description': value.description,
    };
}


